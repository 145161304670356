import React from 'react';
import {useIntl} from "gatsby-plugin-intl";
import TechStackItem from "./TechStackItem";
import {Col, Row} from "react-bootstrap";
import Fade from "react-reveal/Fade";

const TechStackGroup = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale;
    if (!data) {
        return null;
    }

    return (
        <>
            <h1 style={{marginBottom: '20px'}}>{data.category[lang]}</h1>
            <Row style={{justifyContent: "center"}}>
                {data.items
                .sort((a, b) => b.level - a.level)
                .map((item, index) => {
                    return (
                        <Col xs={3} sm={2} lg={1} style={{marginBottom: 10}} >
                            <Fade
                                duration={100 + (data.items.length * 100)  }
                                delay={100 + (index * 100)  }
                                distance="30px"
                            >
                            <TechStackItem key={item.id} data={item}/>
                            </Fade>
                        </Col>

                    )
                })}
            </Row>
        </>
    );
};

export default TechStackGroup;
