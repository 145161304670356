import React from 'react';
import {useIntl} from "gatsby-plugin-intl";
import TechStackImg from "../../Image/TechStackImg";
import { Tooltip } from 'react-tooltip';
import { renderToStaticMarkup } from 'react-dom/server';

const TechStackItem = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale;
    if (!data) {
        return null;
    }

    const title = data.title[lang] || data.title['en'];
    const imageFile = `techstack/${data.id}`

    const getTooltipContent = () => {
        return renderToStaticMarkup(
            <>
                <h4>{title}</h4>
            </>
        )
    }

    return (
        <div
            data-tooltip-id="my-tooltip"
            data-tooltip-html={getTooltipContent()}
        >
            <TechStackImg alt={title} filename={imageFile} />
            <Tooltip
                style={{ fontSize: '1.5rem'}} 
                id="my-tooltip" />
        </div>
    );
};

export default TechStackItem;
