import React, {useContext, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import Title from '../Title/Title';
import __ from '../../helpers/translate';
import Fade from 'react-reveal/Fade';
import PortfolioContext from "../../context/context";
import TechStackGroup from "./components/TechStackGroup";

const Techstack = () => {
    const {techStack} = useContext(PortfolioContext);

    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    return (
        <section id="techstack">
            <Container>
                <div className="techstack-wrapper">
                    <Title title={__("techstack")}/>
                    <p>{__("techstack_info")}</p>
                        <div>
                            {techStack.active && techStack.active.map((item) => {
                                    return (
                                        <TechStackGroup key={item} data={techStack.data[item]}/>
                                    )
                                }
                            )}
                        </div>
                    {/* <p>{__("techstack_footer")}</p> */}
                </div>
            </Container>
        </section>
    );
};

export default Techstack;
