import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';
import { useIntl } from "gatsby-plugin-intl"
import __ from '../../helpers/translate';
import TechItems from "./TechItems";

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const intl = useIntl()
  const lang = intl.locale;
  
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const txtYear = __('year');
  const txtTech = __('tech');

  return (
    <section id="projects" className="dark">
      <Container>
        <div className="project-wrapper">
          <Title title={__("projects")} />
          <p className='info'>{__("projects_info")}</p>
          <Row>
          {projects.map((project) => {
            const { title, info, info2, year, url, repo, img, id } = project;
            let visible = typeof project['visible'] !== 'undefined' ? project['visible'] : true;
            if(!visible){
              return null;
            }
            return (
                <Col key={id} lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={200}
                    distance="30px"
                  >
                    <div className="project-wrapper__text mb-8">
                      <h3 className="project-wrapper__text-title">{title[lang] || title['en'] || title || 'Project Title'}</h3>

                      <div className="project-wrapper__image mb-4">
                          <div data-tilt className="thumbnail">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                    </div>


                      <div>
                        <p>
                          {info[lang] || info['en'] ||
                            '....'}
                        </p>
                        <p className="project-wrapper__extra mb-4">{txtYear}: {year || ''}</p>
                        <p className="project-wrapper__extra mb-4"><TechItems items={info2[lang] || info2['en']} /></p>
                      </div>
                      {url && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        {__('see_live')}
                      </a>
                      )}

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>

            );

          })}
            </Row>

        </div>
      </Container>
    </section>
  );
};

export default Projects;
