import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from "gatsby-plugin-intl"
import __ from '../../helpers/translate';

const TechItems = ({items}) => {
  const intl = useIntl()
  const lang = intl.locale;

  const itemsArray = items.split(',').map(item => item.trim());

  const txtYear = __('year');
  const txtTech = __('tech');

  return (
      <>
        {itemsArray.map((item, index) => {
          return (
            <span className={'tech-item'} key={index}>
              {item}
            </span>
          )
        })}
      </>
  );
};

export default TechItems;
