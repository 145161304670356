import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import Icon from '../Icon/Icon'

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;
  
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <Icon name="up" size="2x" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || 'https://github.com/millsoft'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <Icon name={name} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()}
        </p>
        <a 
        style={{color: 'gray'}}
        href="/impressum.html">Impressum</a>

      </Container>
    </footer>
  );
};

export default Footer;
