import React, {useState, useEffect} from 'react';
import { Link } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl"
//import RubberBand from 'react-reveal/RubberBand';


const LanguageSelector = () => {

  const intl = useIntl()
  
  const availableLanguages = [
    {
      lang: 'en',
      url: '/en',
      name: 'English'
    },
    {
      lang: 'de',
      url: '/',
      name: 'German'
    },
  ];



  const [opacity, setOpacity] = useState(1);
  const [display, setDisplay] = useState('block');
  

  const fadeOutX = 300;

  useEffect(() => {
    // Update the document title using the browser API
    window.onscroll =()=>{

      //calculate scroll fade:
      let val = (window.scrollY * 100) / fadeOutX;
      if(val > 100){
        val = 100;
        setDisplay('none')
      }else{
        setDisplay('block')
      }

      setOpacity(1 - (val / 100) )


    }
  });
  
  const containerStyle = {
    opacity: opacity,
    display: display
    
  };
  
  return (
    <div className="language" style={containerStyle}>
      <div className="language__text">This site is also available in {availableLanguages.map((lang => {
        if(intl.locale !== lang.lang){
          return (
          <Link key={lang.lang} to={`${lang.url}`}>{lang.name}</Link>
          )
        }

        return '';

      }))} </div>
    </div>
  );
};


export default LanguageSelector;
