import { useContext } from 'react';
import PortfolioContext from '../context/context';
import { useIntl } from "gatsby-plugin-intl"


//Translation helper
const __ = (source, key) => {
    const intl = useIntl()
    const locale = intl.locale;
    const { contact, hero, head, projects, about} = useContext(PortfolioContext);

    const map = {
      head,
      hero,
      about,
      projects,
      contact
    };

    if(key === undefined){
        //use normal translation
        return intl.formatMessage({ id: source });
    }

    if(typeof map[source] === 'undefined' || typeof map[source][key] === 'undefined'){
      return '';
    }
  
    if(typeof map[source][key] === 'string'){
      return map[source][key];
    }else if(typeof map[source][key] === 'object'){
      return map[source][key][locale];
    }else{
      throw new Error("Unrecognized value type");
    }
  
  }

  export default __