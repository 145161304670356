import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import __ from '../../helpers/translate';



const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { name } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <div className="background"></div>
      <Container className="content">
        
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
        
        <div>
        <h1 className="hero-title">
            {__('hero', 'title') || 'Hi, my name is'}{' '}
            <nobr><span className="text-color-main">{name || 'Michael Milawski'}</span></nobr>
            <br />
            {__('hero', 'subtitle') || "I am a Fullstack Developer."}
          </h1>
        </div>
        

        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {__('hero', 'cta') || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
