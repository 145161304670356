import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCoffee,
  faAddressBook,
  faLayerGroup,
  faAngleUp,
  faFile

} from '@fortawesome/free-solid-svg-icons'

import {
    faLinkedin,
    faXing,
    faGithub,
  } from '@fortawesome/free-brands-svg-icons'

//import { githubButtons } from '../../mock/data';

const availableIcons = {
    default: faAddressBook,
    coffee: faCoffee,
    linkedin: faLinkedin,
    xing: faXing,
    github: faGithub,
    stackshare: faLayerGroup,
    up: faAngleUp,
    file: faFile
}

const Icon = ({name, size}) => {

    if(name === null || typeof availableIcons[name] === 'undefined'){
        name = 'default';
    }
    size = size || '1x';
    const icon = availableIcons[name];

  return (
    <FontAwesomeIcon icon={icon} size={size} />
  );
};

export default Icon;
