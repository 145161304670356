import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import { navigate } from 'gatsby'
import gatsbyConfig from '../../gatsby-config'

const Index = ({location}) => {
  const { description } = headData;
  let lang = 'en';

  if(typeof window !== 'undefined'){
    if(location.pathname === "/"){

      const availableLanguages = ['de', 'en'];
      lang = window.navigator.language.substring(0,2).toLowerCase();
      if(!availableLanguages.includes(lang)){
        lang = 'en';
      }

      if(lang !== 'de'){
        navigate("/" + lang);
        return <p style={{fontWeight: 'bold'}}>Loading site...</p>
      }
      
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Michael Milawski'} - Fullstack Web Developer</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || 'Web Developer'} />
      </Helmet>
      <App />
    </>
  );
};

export default Index;