import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Works from './Works/Works';
import Techstack from './Techstack/Techstack';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import LanguageSelector from './LanguageSelector/LanguageSelector';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData, worksData, footerData , techStackData, certsData} from '../mock/data';
import Certs from "./Certs/Certs";

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [techStack, setTechStack] = useState([]);
  const [certs, setCerts] = useState([]);
  const [works, setWorks] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setWorks([...worksData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
    setTechStack({ ...techStackData });
    setCerts({ ...certsData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer, works, techStack, certs }}>
      <div id="app" className="dark">
        <LanguageSelector />
        <Hero />
        <About />
        <Projects />
        {/*<Works />*/}
        <Techstack />
        {/*<Certs />*/}
        <Contact />
        <Footer />
      </div>

    </PortfolioProvider>
  );
}

export default App;
